<template>
  <div class="device-container">
    <Header @changeCity="handleChangeCity" :cityValue="cityValue"/>
       <slot></slot>
    <Footer />
    <right-nav />
</div>
</template>

<script>
  import Header from '@/components/header'
  import Footer from '@/components/footer'
  import RightNav from '@/components/rightNav'
  export default {
    props:['handleChangeCity','cityValue'],
    components: {
      Header,
      Footer,
      RightNav
    }
  }
</script>

<style scoped lang="scss">
</style>
