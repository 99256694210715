import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useDeviceStore } from '@/store/deviceStore'

export const useDatePickerStore = defineStore('deviceDatePickerStore', () => {
  const datePickerData = ref({
    startDate: '',
    endDate: '',
  })
  const { setDeviceSearchParams,searchDevice } = useDeviceStore()

  const handleStartDateChange = (startDate) => {
    datePickerData.value.startDate = startDate
    setDeviceSearchParams(datePickerData.value)
    if (datePickerData.value.startDate && datePickerData.value.endDate) {
      searchDevice()
    }
  }

  const handleEndDateChange = (endDate) => {
    datePickerData.value.endDate = endDate
    setDeviceSearchParams(datePickerData.value)
    if (datePickerData.value.startDate && datePickerData.value.endDate) {
      searchDevice()
    }
  }

  return {
    datePickerData,
    handleEndDateChange,
    handleStartDateChange,
  }
})
