import { defineStore } from 'pinia'
import { assign } from 'lodash-es'
import { useDeviceStore } from '@/store/deviceStore'
import { ref } from 'vue'

export const usePaginationStore = defineStore('devicePaginationStore', () => {
  const paginationData = ref({
    isFirstPage: true,
    isLastPage: false,
    pageSize: 20,
    totalRow: 0,
    currentPage: 1,
    totalPage: 0,
  })

  const { setDeviceSearchParams } = useDeviceStore()

  const setPaginationData = (PaginationDataParams) => {
    assign(paginationData.value, PaginationDataParams)
    setDeviceSearchParams(paginationData.value)
  }
  const handleCurrentPageChange = (curPage) => {
    const { searchDevice } = useDeviceStore()
    setPaginationData({ currentPage: curPage })
    searchDevice()
  }
  const handleNextClick = () => {
    if (!paginationData.value.isFirstPage) {
      setPaginationData({ currentPage: paginationData.value.currentPage + 1 })
      const { searchDevice } = useDeviceStore()
      searchDevice()
    }
  }
  const handlePrevClick = () => {
    if (!paginationData.value.isFirstPage) {
      setPaginationData({ currentPage: paginationData.value.currentPage - 1 })

      const { searchDevice } = useDeviceStore()

      searchDevice()
    }
  }

  return {
    handlePrevClick,
    handleNextClick,
    handleCurrentPageChange,
    setPaginationData,
    paginationData,
  }
})
