import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useDeviceStore } from '@/store/deviceStore'
import { getClassifyList } from '@/api/getTypes'

export const useTypePickerStore = defineStore('deviceTypePickerStore', () => {
  const typePickerData = ref({
    TypeMaps: [],
    typeNames: [],
    typeIds: [],
    currentTypeId: '',
    currentTypeName: '',
  })
  const deviceStore = useDeviceStore()
  const { setDeviceSearchParams,searchDevice } = useDeviceStore()

  const handleChangeType = async ({ type_id, type_name }) => {
    deviceStore.deviceStoreData.currentTypeId = type_id
    deviceStore.deviceStoreData.currentTypeName = type_name
    typePickerData.value.currentTypeId = type_id
    typePickerData.value.currentTypeName = type_name
    if (!typePickerData.value.typeNames.includes(type_name)) {
      typePickerData.value.typeNames.push(type_name)
      typePickerData.value.typeIds.push(type_id)
    }
    setDeviceSearchParams(typePickerData.value)
    console.log(`handleChangeType, currentTypeId :${typePickerData.value.currentTypeId}`)
    searchDevice()
  }

  const clearType = () => {
    typePickerData.value.currentTypeName = ''
    typePickerData.value.currentTypeId = ''
    setDeviceSearchParams(typePickerData.value)
    searchDevice()
  }

  const handleDeleteType = (typeName) => {
    typePickerData.value.currentTypeName = ''
    typePickerData.value.currentTypeId = ''
    const index = typePickerData.value.typeNames.findIndex((name) => name === typeName)
    typePickerData.value.typeNames.splice(index, 1)
    typePickerData.value.typeIds.splice(index, 1)
    setDeviceSearchParams(typePickerData.value)
    searchDevice()
  }

  const handlePickType = (typeName, typeId) => {
    typePickerData.value.currentTypeId = typeId
    typePickerData.value.currentTypeName = typeName
    deviceStore.deviceStoreData.currentTypeId = typeId
    deviceStore.deviceStoreData.currentTypeName = typeName
    setDeviceSearchParams(typePickerData.value)
    searchDevice()
  }

  const getTypeMaps = async () => {
    const res = await getClassifyList()
    if (res.status === 200) {
      const { data } = res
      typePickerData.value.TypeMaps = []
      data.map(({ type_id, type_name }) => {
        typePickerData.value.TypeMaps.push([type_id, type_name])
      })
    } else {
      console.log('getClassifyList happened error')
    }
  }

  getTypeMaps()

  const handleSelectAllTypes = () => {
    clearType()
  }

  return {
    typePickerData,
    handleChangeType,
    clearType,
    handleDeleteType,
    handlePickType,
    handleSelectAllTypes,
  }
})
