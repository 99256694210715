<template>
  <div class="card-item">
    <div class="img-container">
      <img :src="cardImage" class="pic" alt="" />
    </div>

    <div class="description">
      <div class="title">
        <span v-html="title"></span>
      </div>

      <div class="shell">
        <div class="price">
          {{ `${rentalFee} / ${rentalType}` }}
        </div>
        <div class="announcer">
          {{ creator }}
        </div>
      </div>

      <div class="location"><i class="el-icon-location-outline"></i>{{ address }}</div>
    </div>
  </div>
</template>

<script>
  import img from '@/assets/images/cardItem_onerror_default.png'
  import kits from '@/kits'
  export default {
    props: ['image', 'title', 'price', 'creator', 'address'],
    computed: {
      cardImage() {
        return kits.img.url(this.image)
      },
      rentalFee() {
        const rentalArr = JSON.parse(this.price)
        const { rental_fee } = rentalArr[0]
        return rental_fee
      },
      rentalType() {
        const rentalArr = JSON.parse(this.price)
        let { rental_type } = rentalArr[0]
        if (rental_type == '0') {
          rental_type = '日'
        } else if (rental_type == '1') {
          rental_type = '月'
        } else if (rental_type == '2') {
          rental_type = '半年'
        } else {
          rental_type = '年'
        }
        return rental_type
      },
    },
    methods: {
      replaceByDefault(e) {
        e.target.src = img
      },
    },
  }
</script>

<style scoped lang="scss">
  @import '@/assets/css/cssValue';

  .card-item {
    height: $baseWidth/4;
    width: $baseWidth/5.2;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 2px solid transparent;
    margin: 4px;

    &:hover {
      transition: 0.3s;
      cursor: pointer;
      border: 2px solid #ff992b;
    }
    .img-container {
      flex: 2.5;
      img {
        width: 100%;
        height: $baseWidth/6;
        object-fit: cover;
      }
    }
    .description {
      flex: 1;
      padding: 10px 10px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        position: relative;
        top: -0.5rem;
        font-size: 0.5rem;
        color: #333;
        font-weight: bold;
        width: 100%;
        height: 2rem;
        overflow: hidden;
      }
      .shell {
        display: flex;
        align-items: center;
        gap: 2rem;
        font-size: 1rem;
        color: #a8aebf;
        justify-content: space-between;
        .price {
          font-size: 0.5rem;
          color: #fd5c00;

          margin: 0 4px;
        }
        .announcer {
          font-size: 0.4rem;
        }
      }
      .location {
        font-size: 0.4rem;
        color: #a8aebf;
      }
    }
  }
</style>
