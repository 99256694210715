<template>
  <section class="base-list">
    <div class="cards">
      <div
        class="card-item"
        v-for="(item, index) in deviceListData"
        :key="index"
        @click="$router.push({ name: 'deviceDetails', query: { id: item.device_lease_id } })">
        <CardItem
          :image="item.show_img"
          :title="item.name"
          :price="item.device_rental_fee_json"
          :creator="item.company_name"
          :address="item.address" />
      </div>
    </div>
  </section>
</template>

<script>
  import CardItem from '@/pages/device/devicePage/cardItem.vue'
  export default {
    props: ['deviceListData'],
    components: {
      CardItem,
    },
  }
</script>

<style scoped lang="scss">
  .cards {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
  }
</style>
