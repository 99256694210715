<template>
  <div class="filter-item">
    <div class="form-label">发布日期：</div>
    <div class="form">
      <el-date-picker
        v-model="dateRange"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        value-format="yyyy-MM-dd HH:mm:ss">
      </el-date-picker>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['startDate', 'endDate', 'handleEndDateChange', 'handleStartDateChange'],
    data() {
      return {
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
        dateRange: '',
      }
    },
    watch: {
      dateRange() {
        const startDate = this.dateRange[0]
        const endDate = this.dateRange[1]
        this.handleStartDateChange(startDate)
        this.handleEndDateChange(endDate)
      },
    },
  }
</script>

<style lang="scss">
  .el-range-editor.el-input__inner .el-range-separator {
    width: 10% !important;
  }
</style>
