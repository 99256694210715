import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useDeviceStore } from '@/store/deviceStore'

export const usePricePickerStore = defineStore('devicePricePickerStore', () => {
  const pricePickerData = ref({
    maxPrice: '',
    minPrice: '',
    rentType: '',
  })
  const { setDeviceSearchParams,searchDevice } = useDeviceStore()

  const handleMinPriceChange = (minPrice) => {
    pricePickerData.value.minPrice = minPrice
    setDeviceSearchParams(pricePickerData.value)
    
  }
  const handleMaxPriceChange = (maxPrice) => {
    pricePickerData.value.maxPrice = maxPrice
    setDeviceSearchParams(pricePickerData.value)
    
  }

  const handleRentTypeChange = (rentType) => {
    pricePickerData.value.rentType = rentType
    setDeviceSearchParams(pricePickerData.value)
    searchDevice()
  }


    


  return {
    pricePickerData,
    handleMinPriceChange,
    handleMaxPriceChange,
    handleRentTypeChange,
  }
})
