<template>
  <div>
    <section class="filter-form">
      <TypePicker
        :TypeMaps="typePickerStore.typePickerData.TypeMaps"
        :handleChangeType="handleChangeType"
        :handlePickType="handlePickType" 
        :clearTypes="handleSelectAllTypes"
        />
      <RegionPicker :handleCityChange="handleCityChange" :cityVal="deviceStore.deviceStoreData.address"/>
      <PricePicker
        :maxPrice="pricePickerStore.pricePickerData.maxPrice"
        :minPrice="pricePickerStore.pricePickerData.minPrice"
        :handleMinPriceChange="handleMinPriceChange"
        :handleMaxPriceChange="handleMaxPriceChange"
        :handleRentTypeChange="handleRentTypeChange"
         />
      <DatePicker
        :startDate="datePickerStore.datePickerData.startDate"
        :endDate="datePickerStore.datePickerData.endDate"
        :handleStartDateChange="handleStartDateChange"
        :handleEndDateChange="handleEndDateChange" />
    </section>
    <section class="filter-form current-filter">
      <CurrentFilter
        :currentFilteredType="typePickerStore.typePickerData.currentTypeName"
        :reselect="clearType" />
    </section>
  </div>
</template>

<script>
  import DatePicker from '@/components/filterForm/datePicker'
  import PricePicker from '@/components/filterForm/pricePicker'
  import RegionPicker from '@/components/filterForm/regionPicker'
  import TypePicker from '@/components/filterForm/typePicker'
  import CurrentFilter from '@/components/filterForm/currentFilter'
  import { useDeviceStore } from '@/store/deviceStore'
  import { usePricePickerStore } from '@/store/deviceStore/pricePickerStore'
  import { useTypePickerStore } from '@/store/deviceStore/typePickerStore'
  import { useDatePickerStore } from '@/store/deviceStore/datePickerStore'
  export default {
    components: {
      DatePicker,
      PricePicker,
      RegionPicker,
      TypePicker,
      CurrentFilter,
    },
    setup() {
      const deviceStore = useDeviceStore()
      const { handleCityChange } = useDeviceStore()
      const typePickerStore = useTypePickerStore()
      const { clearType, handleDeleteType, handlePickType,handleSelectAllTypes } = useTypePickerStore()
      const pricePickerStore = usePricePickerStore()
      const datePickerStore = useDatePickerStore()
      const { handleStartDateChange, handleEndDateChange  } = useDatePickerStore()
      const { handleChangeType } = useTypePickerStore()
      const { handleMaxPriceChange, handleMinPriceChange,handleRentTypeChange } = usePricePickerStore()

      return {
        deviceStore,
        handleCityChange,
        typePickerStore,
        pricePickerStore,
        datePickerStore,
        handleChangeType,
        handleMaxPriceChange,
        handleMinPriceChange,
        handleStartDateChange,
        handleEndDateChange,
        clearType,
        handleDeleteType,
        handlePickType,
        handleSelectAllTypes,
        handleRentTypeChange
      }
    },
  }
</script>

<style scoped lang="scss"></style>
