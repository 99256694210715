<template>
  <div class="filter-item" @change="handleChange">
    <div class="form-label">价格：</div>
    <div class="form">
      <el-input
        placeholder="0.00"
        size="small"
        style="width: 8rem"
        v-model="minPrice"
        type="number"
        @change="handleMinPriceChange">
        <template slot="prepend">¥</template>
      </el-input>
      ~
      <el-input
        placeholder="0.00"
        size="small"
        style="width: 8rem"
        v-model="maxPrice"
        type="number"
        @change="handleMaxPriceChange">
        <template slot="prepend">¥</template>
      </el-input>
    </div>
    <div class="form-label rent">租期：</div>
    <el-select v-model="RentType" placeholder="请选择">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        :disabled="item.disabled">
      </el-option>
    </el-select>
    <!-- <el-button :plain="true" @click="open">错误</el-button> -->
  </div>
</template>

<script>
  export default {
    props: ['handleMaxPriceChange', 'handleMinPriceChange', 'handleRentTypeChange'],
    data() {
      return {
        minPrice: 0,
        maxPrice: 0,
        options: [
          {
            value: '0',
            label: '日',
          },
          {
            value: '1',
            label: '月',
          },
          {
            value: '2',
            label: '半年',
          },
          {
            value: '3',
            label: '年',
          },
          {
            value: '',
            label: '请选择',
          },
        ],
        RentType: '',
      }
    },
    watch: {
      minPrice() {
        this.handleMinPriceChange(this.minPrice)
        this.handlePriceDataChange()
      },
      maxPrice() {
        this.handleMaxPriceChange(this.maxPrice)
        this.handlePriceDataChange()
      },
      RentType() {
        this.handleRentTypeChange(this.RentType)
      },
    },
    methods: {
      open() {
        this.$message.error('请输入正确的价格')
      },
    },
    setup() {
      const handlePriceDataChange = () => {
        console.log('handlePriceDataChange') 
        if (this.minPrice &&  this.maxPrice && this.minPrice > this.maxPrice) {
          this.open()
        }
      }

      const handleChange = () => {
        console.log('handleChange')
      }
      return {
        handlePriceDataChange,
        handleChange
      }
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::v-deep input[type='number'] {
    -moz-appearance: textfield;
  }
  .rent {
    position: relative;
    left: 3rem;
  }
</style>
