import { defineStore } from 'pinia'
import { ref } from 'vue'
import search from '@/api/searchDevice'
import { assign } from 'lodash-es'
import { usePaginationStore } from '@/store/deviceStore/paginationStore'
import { Loading } from 'element-ui'

export const useDeviceStore = defineStore('deviceStore', () => {
  const deviceStoreData = ref({
    startDate: '',
    endDate: '',
    searchValue: '',
    address: '',
    currentTypeId: '',
    currentTypeName: '',
    currentPage: 1,
    minPrice: '',
    maxPrice: '',
    pageSize: 20,
    rentType: '',
  })

  const deviceLists = ref([])

  const clearDeviceLists = () => {
    deviceLists.value = []
  }

  const searchDeviceLists = async (searchValue) => {
    if (searchValue !== undefined && searchValue !== null) {
      deviceStoreData.value.searchValue = searchValue
    }
    const res = await search(
      {
        start_date: deviceStoreData.value.startDate,
        end_date: deviceStoreData.value.endDate,
        name: deviceStoreData.value.searchValue,
        address: deviceStoreData.value.address,
        type_id: deviceStoreData.value.currentTypeId,
        rental_type: deviceStoreData.value.rentType,
        high_price: deviceStoreData.value.maxPrice,
        low_price: deviceStoreData.value.minPrice,
      },
      deviceStoreData.value.currentPage
    )
    if (res.status === 200) {
      console.log('res:  ', res)
      const { data } = res
      const { setPaginationData } = usePaginationStore()
      clearDeviceLists()
      data.list.forEach((list) => {
        deviceLists.value.push(list)
      })
      setPaginationData({
        isFirstPage: data.firstPage,
        isLastPage: data.lastPage,
        totalPage: Math.ceil(data.total / 20),
      })
    } else {
      console.log(`searchDevice happened error: `, res.msg)
    }
    return true
  }

  const handleCityChange = (selectedCity) => {
    deviceStoreData.value.address = selectedCity
    if (selectedCity === '全部') {
      deviceStoreData.value.address = ''
    }
    console.log(deviceStoreData.value.address)
    searchDevice()
  }

  const setDeviceSearchParams = (params) => {
    assign(deviceStoreData.value, params)
    console.log('deviceStoreData: ', deviceStoreData.value)
  }

  const searchDevice = (value) => {
    Promise.race([searchDeviceLists(value), timeout(300)]).then((displayed) => {
      if (!displayed) {
        showSpinner()
      }
    })
  }
  const showSpinner = () => {
    const loadingInstance = Loading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    console.log('please wait...')
    setTimeout(() => {
      loadingInstance.close()
    }, 1000)
  }
  const timeout = (delay, result) => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(result), delay)
    })
  }
  return { deviceStoreData, searchDevice, setDeviceSearchParams, deviceLists, handleCityChange }
})
